
import mixins from 'vue-typed-mixins';
import { required } from 'vuelidate/dist/validators.min';

import TransferData from 'Modules/Transfer/Transfer.Data.vue';
import Button from 'Control/Button.vue';
import SelectFee from 'Control/SelectFee.vue';
import numberFormater from 'Mixins/numberFormater';
import Asset from 'Entities/publicPresenter/Asset';
import Balance from 'Entities/privatePresenter/Balance';
import AssetValue from 'UI/AssetValue.vue';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import ApiError from 'Entities/ApiError';
import Icon from 'UI/Icon.vue';

interface Data {
    feeSize: 'low' | 'medium' | 'high' | undefined;
    feesError: boolean;
    fromBalances: Balance[];
}

interface Methods {
    completeTransfer: () => void;
    makeRequest: () => void;
    setFees: (data: string) => void;
}

interface Computed {
    isThemeDark: boolean;
    activeAccountId: string;
    fees: Record<string, any>;
    from: string;
    to: string;
    availableBalance: number;
    isTransferAvailable: true | 'not enough funds' | 'quantity is too low';
    receivedAmount: number;
    isNetworkNeeded: boolean;
    isFiatTransfer: boolean;
    feeAssetBalance: Balance;
    isXLMBalanceAvailable: boolean;
    notes: string;
}

export default mixins(TransferData).extend<Data, Methods, Computed>({
    components: {
        Icon,
        SelectFee,
        Button,
        AssetValue,
    },
    mixins: [numberFormater, TransferData],
    data() {
        return {
            feeSize: 'medium',
            previousRoute: '/wallets',
            feesError: false,
            fromBalances: [],
        };
    },
    validations() {
        return {
            feeSize: {
                required,
            },
        };
    },
    computed: {
        isNetworkNeeded() {
            return this.$route.query.isBlockchainNeeded === 'true';
        },
        isFiatTransfer() {
            return this.$route.query.isFiatTransfer === 'true';
        },
        notes() {
            return this.$route.query.notes;
        },
        feeAssetBalance() {
            const selectedFee = this.fees.low;
            return this.fromBalances.find(({ assetSymbol, blockchainName }) => assetSymbol === selectedFee.assetSymbol
                && (blockchainName ? blockchainName === this.ui.blockchain : true));
        },
        isTransferAvailable() {
            if (!this.isNetworkNeeded) {
                return true;
            }
            const selectedFee = this.fees[this.feeSize!];
            const balance = this.fromBalances.find(({ assetSymbol, blockchainName, accountId }) => assetSymbol === selectedFee.assetSymbol
                && (blockchainName ? blockchainName === this.ui.blockchain : true)
                && accountId === this.activeAccountId);
            if (this.ui.asset === selectedFee.assetSymbol) {
                if (selectedFee.amount <= this.ui.quantity) {
                    return true;
                }
                return 'quantity is too low';
            }
            if (balance.free >= selectedFee.amount) {
                return true;
            }
            return 'not enough funds';
        },
        // необходимо проверять, что после вывода на балансе останется хотыбы 1 XLM в случае перевода этого асета
        isXLMBalanceAvailable() {
            if (this.ui.asset !== 'XLM') {
                return true;
            }
            const balance = this.fromBalances.find(({ assetSymbol, accountId }) => assetSymbol === 'XLM'
                && accountId === this.activeAccountId);
            if (this.fees[this.feeSize]?.assetSymbol === 'XLM') {
                return (balance?.free ?? 0) - 1 >= Number(this.ui.quantity) + Number(this.fees[this.feeSize].amount);
            }
            return (balance?.free ?? 0) - 1 >= Number(this.ui.quantity);
        },
        receivedAmount() {
            if (!this.isNetworkNeeded) {
                return this.ui.quantity;
            }
            if (!this.fees || !this.feeSize) {
                return 0;
            }
            const selectedFee = this.fees[this.feeSize];
            if (this.ui.asset === selectedFee.assetSymbol) {
                const amount = this.ui.quantity - selectedFee.amount;
                return amount >= 0 ? amount : 0;
            }
            return this.ui.quantity;
        },
        isThemeDark() {
            return this.$store.getters.isThemeDark;
        },
        activeAccountId() {
            return this.$store.state.Accounts.activeAccountID;
        },
        fees() {
            return this.ui.fees;
        },
        from() {
            if (this.ui.transferType === 'Exchanges') {
                const placement = this.$store.state.Placements.placements.find((p) => p.name === this.ui.fromExchange.id);
                return placement ? placement.name : '';
            }
            const account = this.$store.state.Accounts.accounts.find((a) => a.id === this.activeAccountId);
            return account ? account.name : '';
        },
        to() {
            if (this.ui.transferType === 'Exchanges') {
                const placement = this.$store.state.Placements.placements.find((p) => p.name === this.ui.toExchange.id);
                return placement ? placement.name : '';
            }
            const account = this.$store.state.Accounts.accounts.find((a) => a.id === this.ui.toAccount.id);
            return account ? account.name : '';
        },
        availableBalance() {
            const balance = this.fromBalances.find((b) => b.assetSymbol === this.ui.asset && (b.blockchainName ? b.blockchainName === this.ui.blockchain : true));
            return balance ? balance.free : 0;
        },
    },
    methods: {
        async completeTransfer() {
            if (this.isNetworkNeeded) {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    await this.makeRequest();
                } else {
                    this.feesError = true;
                }
            } else {
                await this.makeRequest();
            }
        },
        async makeRequest() {
            if (!this.isXLMBalanceAvailable) {
                await this.$store.dispatch('Notificator/showErrorNotification', 'You need to keep at least 1 XLM at your balance');
                return;
            }
            if (this.isTransferAvailable === true) {
                try {
                    await this.doTransfer({ activeAccountId: this.$store.state.Accounts.activeAccountID });
                } catch (error) {
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong');
                    }
                } finally {
                    await this.$router.push(this.previousRoute);
                }
            } else if (this.isTransferAvailable === 'not enough funds') {
                await this.$store.dispatch('Notificator/showErrorNotification', 'Not enough funds to perform transfer');
            } else {
                await this.$store.dispatch('Notificator/showErrorNotification', 'Quantity is too low to perform a transfer');
            }
        },
        setFees(value) {
            this.feeSize = value;
            this.feesError = false;
        },
    },
    async mounted() {
        try {
            this.$store.commit(SET_LOADING_ON(undefined));
            const { ui, assetsKeys, assetsValues, previousRoute, fromBalances } = this.$route.query;
            this.ui = ui;
            this.fromBalances = fromBalances.map((b) => new Balance(b));
            const assetsMap = new Map();
            for (let i = 0; i < assetsKeys.length; i += 1) {
                assetsMap.set(assetsKeys[i], new Asset(assetsValues[i]));
            }
            this.assets = assetsMap;
            this.previousRoute = previousRoute;
            await this.getTransferFees(this.activeAccountId);
        } finally {
            this.$store.commit(SET_LOADING_OFF(undefined));
        }
    },
    watch: {
        async feeSize() {
            await this.SET_FEE_SIZE(this.feeSize);
        },
    },
});

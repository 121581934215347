
import mixins from 'vue-typed-mixins';

import Placement from 'Entities/publicPresenter/Placement';
import Blockchain from 'Entities/publicPresenter/Blockchain';
import Asset from 'Entities/publicPresenter/Asset';
import PlacementsRequest from 'Entities/publicPresenter/PlacementsRequest';
import PublicApi from 'Apis/PublicData';
import ApiError from 'Entities/ApiError';
import BlockchainsRequest from 'Entities/publicPresenter/BlockchainsRequest';
import AssetsRequest from 'Entities/publicPresenter/AssetsRequest';
import WalletsApi from 'Apis/Wallets';
import TransferFeeRequestData, { ITransferFeeRequestData } from 'Entities/walletExecutor/TransferFeeRequestData';
import { MFA_ENROLL_FACTOR_TYPES } from 'Config/auth';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import TransferRequestData, { ITransferRequestData } from 'Entities/walletExecutor/TransferRequestData';
import TransferRequest from 'Entities/privatePresenter/TransferRequest';
import router from '@/router';
import TransferHistoryData from 'Modules/TransferHistory/TransferHistory.Data.vue';
import EmptyResult from 'Entities/walletExecutor/EmptyResult';
import { needUpdateTransferHistory } from 'Store/v2/UiActions';

export enum TransferTypes {
    Exchanges = 'Exchanges',
    Accounts = 'Accounts',
}

interface IAccountOrExchangeInfo {
    id: string;
    index: number;
}

interface ISetActiveAssetPayload {
    symbol: string;
    index: number;
}

interface IDoTransferProps {
    activeAccountId: string;
}

export interface TransferUI {
    from: string; // placement name
    to: string; // placement name
    blockchain: string; // blockchain name
    asset: string; // asset symbol
    lastAssetSymbol: string; // last chosen asset symbol
    currentAssetIndex: number; // asset index
    currentBlockchainIndex: number; // blockchain index
    transferType: TransferTypes;
    fromAccount: IAccountOrExchangeInfo;
    toAccount: IAccountOrExchangeInfo;
    fromExchange: IAccountOrExchangeInfo;
    toExchange: IAccountOrExchangeInfo;
    quantity: number;
    fees: Record<string, any> | null;
    feeSize: undefined | 'low' | 'medium' | 'high';
}

interface Data {
    placements: Map<string, Placement>;
    blockchains: undefined | Map<string, Blockchain>;
    assets: undefined | Map<string, Asset>;
    ui: TransferUI;
}

interface Methods {
    // mutations
    SET_PLACEMENTS: (data: Placement[]) => void;
    SET_BLOCKCHAINS: (data: Blockchain[]) => void;
    SET_ASSETS: (data: Asset[]) => void;
    SET_UI: (data: Partial<TransferUI>) => void;
    SET_ACTIVE_ASSET: (data: ISetActiveAssetPayload) => void;
    SET_ACTIVE_BLOCKCHAIN: (data: number) => void;
    SET_TO_ACCOUNT: (data: IAccountOrExchangeInfo) => void;
    SET_FROM_ACCOUNT: (data: IAccountOrExchangeInfo) => void;
    SET_TO_EXCHANGE: (data: IAccountOrExchangeInfo) => void;
    SET_FROM_EXCHANGE: (data: IAccountOrExchangeInfo) => void;
    SET_QUANTITY: (data: number) => void;
    SET_FEE_SIZE: (data: TransferUI['feeSize']) => void;
    // actions
    init: () => void;
    getPlacements: () => void;
    getBlockchains: () => void;
    onUiUpdate: (data: TransferUI) => void;
    getAssests: () => void;
    getTransferFees: (data: string) => void;
    doTransfer: (data: IDoTransferProps) => void;
}

interface Computed {
    GET_PLACEMENTS: Placement[];
    GET_BLOCKCHAINS: Blockchain[];
    GET_ASSETS: Asset[];
    GET_TRANSFER_MIN_SIZE: number;
    GET_BLOCKCHAIN_NEEDED: boolean;
    GET_IS_SELECTED_ASSET_FIAT: boolean;
}

export default mixins(TransferHistoryData).extend<Data, Methods, Computed>({
    mixins: [TransferHistoryData],
    // state
    data() {
        return {
            placements: new Map<string, Placement>(),
            blockchains: undefined,
            assets: undefined as undefined | Map<string, Asset>,
            ui: {
                quantity: 0,
                fromAccount: {
                    id: '',
                    index: 0,
                } as IAccountOrExchangeInfo,
                toAccount: {
                    id: '',
                    index: 0,
                } as IAccountOrExchangeInfo,
                fromExchange: {
                    id: '',
                    index: 0,
                } as IAccountOrExchangeInfo,
                toExchange: {
                    id: '',
                    index: 0,
                } as IAccountOrExchangeInfo,
                blockchain: '',
                asset: '',
                lastAssetSymbol: '',
                feeSize: 'medium',
                fees: null,
                currentAssetIndex: 0,
                currentBlockchainIndex: 0,
                transferType: TransferTypes.Exchanges,
            } as TransferUI,
        };
    },
    // getters
    computed: {
        GET_PLACEMENTS() {
            return Array.from(this.placements?.values() || []);
        },
        GET_BLOCKCHAINS() {
            if (!this.assets) {
                return [];
            }
            return (Array
                .from(this.blockchains?.values() || [])
                .filter((b) => {
                    let flag = false;
                    if (this.assets?.get(this.ui.asset)) {
                        this.assets.get(this.ui.asset)!.transferDetails!.forEach((d) => {
                            if (d.blockchainName === (b as Blockchain).name) {
                                flag = true;
                            }
                        });
                    }
                    return flag;
                }) as Blockchain[])
                .sort(({ name: a }, { name: b }) => {
                    if (a < b) {
                        return -1;
                    }
                    if (a > b) {
                        return 1;
                    }
                    return 0;
                }) as Blockchain[];
        },
        GET_ASSETS() {
            return Array.from(this.assets?.values() || []);
        },
        GET_TRANSFER_MIN_SIZE() {
            const asset = this.assets?.get(this.ui.asset);
            if (!asset) {
                return 0;
            }
            const transferDetail = asset.transferDetails?.find((d) => d.blockchainName === this.ui.blockchain);
            if (!transferDetail || !transferDetail.transferMinSize) {
                return 0;
            }
            return Number(transferDetail.transferMinSize);
        },
        GET_BLOCKCHAIN_NEEDED() {
            const asset = this.assets?.get(this.ui.asset);
            if (!asset) {
                return false;
            }
            const { transferDetails } = asset;
            if (!transferDetails) {
                return false;
            }
            return !!transferDetails[0].blockchainName;
        },
        GET_IS_SELECTED_ASSET_FIAT() {
            if (!this.assets) {
                return false;
            }
            return this.assets.get(this.ui.asset)?.type === 'fiat';
        },
    },
    // actions and mutations
    methods: {
        // mutations
        SET_PLACEMENTS(placements: Placement[]) {
            const map = new Map<string, Placement>();
            placements.forEach((p) => map.set(p.name, p));
            this.placements = map;
        },
        SET_BLOCKCHAINS(blockchains: Blockchain[]) {
            const map = new Map<string, Blockchain>();
            blockchains.forEach((b) => map.set(b.name, b));
            this.blockchains = map;
        },
        SET_ASSETS(assets: Asset[]) {
            const map = new Map<string, Asset>();
            assets.forEach((b) => map.set(b.symbol, b));
            this.assets = map;
        },
        SET_UI(ui: Partial<TransferUI>) {
            this.ui = { ...(this.ui || {}), ...(ui || {}) };
        },
        SET_ACTIVE_ASSET({ symbol, index }: ISetActiveAssetPayload) {
            this.ui.currentAssetIndex = index;
            this.ui.asset = symbol;
            this.ui.blockchain = String(this.assets?.get(symbol)?.transferDetails![0].blockchainName);
            const blockchainsArray = Array.from(this.blockchains?.values() || []).filter((b) => {
                let flag = false;
                this.assets!.get(this.ui.asset)!.transferDetails!.forEach((d) => {
                    if (d.blockchainName === (b as Blockchain).name) {
                        flag = true;
                    }
                });
                return flag;
            });
            blockchainsArray.findIndex((b) => (b as Blockchain).name === this.ui.blockchain);
            this.ui.currentBlockchainIndex = blockchainsArray.findIndex((b) => (b as Blockchain).name === this.ui.blockchain);
        },
        SET_ACTIVE_BLOCKCHAIN(blockchainIndex: number) {
            this.ui.currentBlockchainIndex = blockchainIndex;
            const blockchainsArray = Array.from(this.blockchains?.values() || []).filter((b) => {
                let flag = false;
                this.assets!.get(this.ui.asset)!.transferDetails!.forEach((d) => {
                    if (d.blockchainName === (b as Blockchain).name) {
                        flag = true;
                    }
                });
                return flag;
            });
            this.ui.blockchain = (blockchainsArray[blockchainIndex] as Blockchain).name;
        },
        SET_TO_ACCOUNT(props: IAccountOrExchangeInfo) {
            this.ui.toAccount.id = props.id;
            this.ui.toAccount.index = props.index;
        },
        SET_FROM_ACCOUNT(props: IAccountOrExchangeInfo) {
            this.ui.fromAccount.id = props.id;
            this.ui.fromAccount.index = props.index;
        },
        SET_TO_EXCHANGE(props: IAccountOrExchangeInfo) {
            this.ui.toExchange.id = props.id;
            this.ui.toExchange.index = props.index;
        },
        SET_FROM_EXCHANGE(props: IAccountOrExchangeInfo) {
            this.ui.fromExchange.id = props.id;
            this.ui.fromExchange.index = props.index;
        },
        SET_QUANTITY(quantity: number) {
            this.ui.quantity = quantity;
        },
        SET_FEE_SIZE(size: TransferUI['feeSize']) {
            this.ui.feeSize = size;
        },
        // actions
        async init() {
            await this.getPlacements();
            await this.getBlockchains();
            await this.getAssests();
        },
        async getPlacements() {
            try {
                const req = new PlacementsRequest({});
                const { data: resp } = await PublicApi.publicGetPlacements(req);
                const transferablePlacements = resp
                    .filter((r) => r.type !== 'crypto-spot-decentralized')
                    .sort((a) => (a.type === 'self' ? -1 : 1));
                if (transferablePlacements.length >= 2) {
                    const [{ name: from }, { name: to }] = transferablePlacements;
                    this.SET_UI({ from, to } as Partial<TransferUI>);
                }
                this.SET_PLACEMENTS(transferablePlacements);
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during getting placements', { root: true });
                }
            }
            return Promise.resolve();
        },
        async getBlockchains() {
            try {
                const { data: resp } = await PublicApi.publicGetBlockchains(new BlockchainsRequest({}));
                this.SET_BLOCKCHAINS(resp);
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during getting blockchains', { root: true });
                }
            }
        },
        async onUiUpdate(payload: TransferUI) {
            await this.SET_UI(payload);
            await this.getAssests();
        },
        async getAssests() {
            if (!this.ui) {
                console.error('failed to get from/to in transfer');
                return;
            }
            try {
                const { data: resp } = await PublicApi.publicGetAssets(new AssetsRequest({
                    fromPlacementName: this.ui.transferType === 'Exchanges' ? this.ui.fromExchange.id : this.ui.fromAccount.id,
                    toPlacementName: this.ui.transferType === 'Exchanges' ? this.ui.toExchange.id : this.ui.fromAccount.id,
                    includeTransferDetails: true,
                    perPage: 256,
                }));

                const setDefaultAsset = (list: Asset[]) => {
                    const [initialAsset] = list;
                    const { transferDetails, symbol } = initialAsset;
                    const blockchain = transferDetails?.find((d) => d.active);
                    this.SET_UI({
                        asset: symbol,
                        blockchain: blockchain?.blockchainName,
                        currentAssetIndex: 0,
                        currentBlockchainIndex: 0,
                    } as Partial<TransferUI>);
                    this.ui.lastAssetSymbol = symbol;
                };
                setDefaultAsset.bind(this);

                this.SET_ASSETS(resp);
                if (resp.length > 0) {
                    if (this.ui.lastAssetSymbol) {
                        const asset = resp.find(({ symbol }) => symbol === this.ui.lastAssetSymbol);
                        if (asset) {
                            const { transferDetails, symbol } = asset;
                            const blockchain = transferDetails?.find((d) => d.active);
                            this.SET_UI({
                                asset: symbol,
                                blockchain: blockchain?.blockchainName,
                                currentAssetIndex: this.GET_ASSETS.findIndex(({ symbol: s }) => s === symbol),
                                currentBlockchainIndex: 0,
                            } as Partial<TransferUI>);
                            this.ui.lastAssetSymbol = symbol;
                        } else {
                            setDefaultAsset(resp);
                        }
                    } else {
                        setDefaultAsset(resp);
                    }
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during getting assets', { root: true });
                }
            }
            return Promise.resolve();
        },
        async getTransferFees(activeAccountId: string) {
            try {
                let payload: ITransferFeeRequestData;
                if (this.GET_BLOCKCHAIN_NEEDED || this.GET_IS_SELECTED_ASSET_FIAT) {
                    payload = {
                        amount: Number(this.ui.quantity),
                        assetSymbol: this.GET_ASSETS[this.ui.currentAssetIndex].symbol,
                        blockchainName: this.ui.blockchain,
                        destination: {
                            accountId: this.ui.transferType === 'Accounts' ? this.ui.toAccount.id : activeAccountId,
                            placementName: this.ui.transferType === 'Accounts' ? this.ui.fromAccount.id : this.ui.toExchange.id,
                        },
                        source: {
                            accountId: activeAccountId,
                            placementName: this.ui.transferType === 'Accounts' ? this.ui.fromAccount.id : this.ui.fromExchange.id,
                        },
                    };
                } else {
                    payload = {
                        amount: Number(this.ui.quantity),
                        assetSymbol: this.GET_ASSETS[this.ui.currentAssetIndex].symbol,
                        destination: {
                            accountId: this.ui.transferType === 'Accounts' ? this.ui.toAccount.id : activeAccountId,
                            placementName: this.ui.transferType === 'Accounts' ? this.ui.fromAccount.id : this.ui.toExchange.id,
                        },
                        source: {
                            accountId: activeAccountId,
                            placementName: this.ui.transferType === 'Accounts' ? this.ui.fromAccount.id : this.ui.fromExchange.id,
                        },
                    };
                }
                const { data: res } = await WalletsApi.getTransferFees(new TransferFeeRequestData(payload));
                if (!(res instanceof EmptyResult)) {
                    const feeAsset = Object.keys(res.low!)[0];
                    this.ui.fees = {
                        low: {
                            amount: res.low![feeAsset],
                            assetSymbol: feeAsset.toUpperCase(),
                        },
                        medium: {
                            amount: res.medium![feeAsset],
                            assetSymbol: feeAsset.toUpperCase(),
                        },
                        high: {
                            amount: res.high![feeAsset],
                            assetSymbol: feeAsset.toUpperCase(),
                        },
                    };
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
                }
            }
        },
        async doTransfer(props: IDoTransferProps) {
            if (this.ui.transferType === 'Exchanges' && this.ui.fromExchange.id === 'Bitfinex' && this.ui.toExchange.id === 'OKX' && this.ui.asset === 'DASH') {
                await this.$store.dispatch('Notificator/showErrorNotification', 'DASH Transfers from Bitfinex to OKX are disabled', { root: true });
            } else {
                const totp = await this.$store.dispatch('Auth/getMFAToken', { type: MFA_ENROLL_FACTOR_TYPES.TOTP }, { root: true });
                try {
                    this.$store.commit(SET_LOADING_ON(undefined), { root: true });
                    let payload: ITransferRequestData;
                    if (this.GET_BLOCKCHAIN_NEEDED) {
                        payload = {
                            assetSymbol: this.ui.asset,
                            blockchainName: this.ui.blockchain,
                            amount: Number(this.ui.quantity),
                            feeSize: this.GET_IS_SELECTED_ASSET_FIAT ? 'medium' : this.ui.feeSize,
                            totp,
                            source: {
                                accountId: props.activeAccountId,
                                placementName: this.ui.transferType === 'Accounts' ? this.ui.fromAccount.id : this.ui.fromExchange.id,
                            },
                            destination: {
                                accountId: this.ui.transferType === 'Accounts' ? this.ui.toAccount.id : props.activeAccountId,
                                placementName: this.ui.transferType === 'Accounts' ? this.ui.fromAccount.id : this.ui.toExchange.id,
                            },
                        };
                    } else {
                        payload = {
                            assetSymbol: this.ui.asset,
                            amount: Number(this.ui.quantity),
                            totp,
                            source: {
                                accountId: props.activeAccountId,
                                placementName: this.ui.transferType === 'Accounts' ? this.ui.fromAccount.id : this.ui.fromExchange.id,
                            },
                            destination: {
                                accountId: this.ui.transferType === 'Accounts' ? this.ui.toAccount.id : props.activeAccountId,
                                placementName: this.ui.transferType === 'Accounts' ? this.ui.fromAccount.id : this.ui.toExchange.id,
                            },
                        };
                    }
                    const { data: res } = await WalletsApi.transfer(new TransferRequestData(payload));
                    const { data: transfer } = await WalletsApi.privateGetTransfer(new TransferRequest({
                        id: res.transferId,
                    }));
                    this.UPDATE_TRANSFER(transfer.serialize());
                    await this.$store.dispatch('Notificator/showSuccessNotification', 'Transfer has been successfully created');
                    await router.replace('/wallets');
                } catch (error) {
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
                    } else {
                        await this.$store.dispatch('Notificator/showErrorNotification', 'Error during performing transfer', { root: true });
                    }
                    await router.replace('/wallets');
                } finally {
                    await this.$store.dispatch(needUpdateTransferHistory(undefined), { root: true });
                    this.$store.commit(SET_LOADING_OFF(undefined), { root: true });
                }
            }
        },
    },
});
